import {useEffect, useState} from 'react'

import {useRouter} from 'next/router'

/**
 * Get the full current url. This needs to use a mix of window.location and Next's router
 */
export const useUrl = () => {
  const {asPath} = useRouter()
  const [url, setUrl] = useState<string | null>(null)
  useEffect(() => {
    const host = window.location.host
    const protocol = window.location.protocol
    setUrl(`${protocol}//${host}${asPath}`)
  }, [asPath])
  return {url}
}
