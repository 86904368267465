import {z} from 'zod'

import Link from 'next/link'

import {IconDefinition} from '@fortawesome/fontawesome-common-types'
import {faTiktok} from '@fortawesome/free-brands-svg-icons'
import {faFacebook} from '@fortawesome/free-brands-svg-icons/faFacebook'
import {faInstagram} from '@fortawesome/free-brands-svg-icons/faInstagram'
import {faLinkedin} from '@fortawesome/free-brands-svg-icons/faLinkedin'
import {faTwitter} from '@fortawesome/free-brands-svg-icons/faTwitter'
import {faYoutube} from '@fortawesome/free-brands-svg-icons/faYoutube'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const icons: {
  [key: string]: IconDefinition
} = {
  linkedin: faLinkedin,
  instagram: faInstagram,
  facebook: faFacebook,
  youtube: faYoutube,
  twitter: faTwitter,
  tiktok: faTiktok,
}

const SocialLinkComponentProps = z.object({
  link: z.string(),
  className: z.string(),
})

const SocialLinkComponent = ({
  link,
  className = 'text-white',
}: z.infer<typeof SocialLinkComponentProps>) => {
  let platform = ''
  if (link.includes('linkedin')) {
    platform = 'linkedin'
  } else if (link.includes('facebook')) {
    platform = 'facebook'
  } else if (link.includes('instagram')) {
    platform = 'instagram'
  } else if (link.includes('youtube')) {
    platform = 'youtube'
  } else if (link.includes('twitter')) {
    platform = 'twitter'
  } else if (link.includes('tiktok')) {
    platform = 'tiktok'
  }
  const icon = icons[platform]

  if (!platform || !icon) {
    return <></>
  }

  return (
    <Link href={link} legacyBehavior>
      <a
        rel="noopener noreferrer"
        target="_blank"
        aria-label={`Follow us on ${platform}!`}
      >
        <FontAwesomeIcon icon={icon} className={className} />
      </a>
    </Link>
  )
}

export default SocialLinkComponent
