import Script from 'next/script'

import {
  ANALYTICS_ENABLED,
  PARSELY_SITE_ID,
} from '../../lib/analytics/appAnalytics'

export default function ParselyScript() {
  return (
    <>
      {ANALYTICS_ENABLED && (
        <Script
          id="parsely"
          type="text/plain"
          data-cookieconsent="statistics"
          src={`https://cdn.parsely.com/keys/${PARSELY_SITE_ID}/p.js`}
        />
      )}
    </>
  )
}
