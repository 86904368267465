import {useController} from 'react-hook-form'
import Select from 'react-select'

export const options = [
  {label: 'Yes', value: true},
  {label: 'No', value: false},
]

/**
 * Render a single select dropdown of eyecare roles.
 *
 * @param control
 * @param inputName
 * @returns {JSX.Element}
 * @constructor
 */
export default function BooleanSelectInput({control, inputName}) {
  const {
    field: {onChange, onBlur, name, value, ref},
  } = useController({
    name: inputName,
    control,
    rules: {required: 'This question is required'},
  })

  return (
    <Select
      id={name}
      instanceId={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      options={options}
    />
  )
}
