import {createContext, useContext, useEffect, useState} from 'react'

export const GPTContext = createContext({
  GPTHasLoaded: false,
})

interface GPTProviderProps {
  children?: JSX.Element | JSX.Element[]
}

export const GPTProvider = ({children}: GPTProviderProps) => {
  const [GPTHasLoaded, setGPTHasLoaded] = useState(false)

  useEffect(() => {
    // Make sure that googletag.cmd exists before running any googletag functions:
    window.googletag = window.googletag || {}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore: google publisher tag docs specify this approach
    googletag.cmd = googletag.cmd || []
    googletag.cmd.push(() => setGPTHasLoaded(true))
  }, [])

  return (
    <GPTContext.Provider
      value={{
        GPTHasLoaded,
      }}>
      {children}
    </GPTContext.Provider>
  )
}

export const useGPT = () => {
  return useContext(GPTContext)
}
