import {
  fbqCustomEvent,
  gtagEvent,
  lintrkEvent,
  mixpanel,
} from '../../../lib/analytics/appAnalytics'
import {baseUrl} from '../../../lib/constants'

/**
 *  This function makes API calls to third party platforms for conversion tracking and analytics purposes.
 * @param url the slug or url where the sign-up occurred
 */
export default function trackCompletedSignUp(url: string) {
  const eventName = 'Submitted Glance Subscribe Form'
  const page = url || baseUrl

  // TODO:
  // if (userId) {
  //   mixpanel.alias(userId, mixpanel.get_distinct_id())
  // }

  mixpanel.track(eventName, {
    Page: page,
  })
  gtagEvent({
    action: eventName,
    category: 'glance-subscription',
    label: page,
    value: undefined,
  })
  fbqCustomEvent(eventName, {
    page,
  })
  // LinkedIn
  lintrkEvent(4645348)
}
