import {z} from 'zod'

export const UserProfileSchema = z.object({
  id: z.number(),
  created_datetime: z.string(),
  modified_datetime: z.string(),
  metadata: z.object({}).passthrough().nullable(),
  uuid: z.string(),
  name: z.string(),
  first_name: z.string(),
  last_name: z.string().nullable(),
  country: z.string().length(2),
  role: z.enum([
    'Optometrist',
    'Ophthalmologist',
    'Optician',
    'Optometric Technician',
    'Ophthalmic Technician',
    'Practice Staff',
    'Industry Professional',
    'Consumer/Patient',
  ]),
  graduation_year: z.string().nullable(),
  is_practice_owner: z.boolean().nullable(),
  specialties: z.array(z.string()).nullable(),
  do_not_email: z.boolean().default(false),
})
export type UserProfile = z.infer<typeof UserProfileSchema>

export const UserSchema = z.object({
  email: z.string(),
  id: z.number(),
  impersonation_release_url: z.string().optional(),
  is_staff: z.boolean(),
  profile: UserProfileSchema.nullable(),
  groups: z.array(z.string()),
})
export type User = z.infer<typeof UserSchema>

export const ImpersonationDataSchema = z
  .object({
    release_url: z.string(),
  })
  .optional()
export type ImpersonationData = z.infer<typeof ImpersonationDataSchema>
