import {
  fbqCustomEvent,
  gtagEvent,
  mixpanel,
  parselyTrackLeadCapture,
} from '../../../lib/analytics/appAnalytics'
import {baseUrl} from '../../../lib/constants'

export default function trackViewedSubscribeModal() {
  // This function makes API calls to third party platforms for conversion tracking and analytics purposes.
  const eventName = 'Viewed Glance Subscribe Modal'
  const page = `${baseUrl}${window.location.pathname}`

  mixpanel.track(eventName, {
    Page: page,
  })
  gtagEvent({
    action: eventName,
    category: 'glance-subscription',
    label: page,
    value: undefined,
  })
  fbqCustomEvent(eventName, {
    page,
  })
  parselyTrackLeadCapture(eventName)
}
