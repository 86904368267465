import {useContext} from 'react'

import Script from 'next/script'

import {AuthenticationContext} from '../../lib/auth'

export default function MixpanelInit() {
  const {user} = useContext(AuthenticationContext)

  return (
    <>
      <Script
        id="mixpanel-init"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(f,b){if(!b.__SV){var e,g,i,h;window.mixpanel=b;b._i=[];b.init=function(e,f,c){function g(a,d){var b=d.split(".");2==b.length&&(a=a[b[0]],d=b[1]);a[d]=function(){a.push([d].concat(Array.prototype.slice.call(arguments,0)))}}var a=b;"undefined"!==typeof c?a=b[c]=[]:c="mixpanel";a.people=a.people||[];a.toString=function(a){var d="mixpanel";"mixpanel"!==c&&(d+="."+c);a||(d+=" (stub)");return d};a.people.toString=function(){return a.toString(1)+".people (stub)"};i="disable time_event track track_pageview track_links track_forms track_with_groups add_group set_group remove_group register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking start_batch_senders people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user people.remove".split(" ");
          for(h=0;h<i.length;h++)g(a,i[h]);var j="set set_once union unset remove delete".split(" ");a.get_group=function(){function b(c){d[c]=function(){call2_args=arguments;call2=[c].concat(Array.prototype.slice.call(call2_args,0));a.push([e,call2])}}for(var d={},e=["get_group"].concat(Array.prototype.slice.call(arguments,0)),c=0;c<j.length;c++)b(j[c]);return d};b._i.push([e,f,c])};b.__SV=1.2;e=f.createElement("script");e.type="text/javascript";e.async=!0;e.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?
          MIXPANEL_CUSTOM_LIB_URL:"file:"===f.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";g=f.getElementsByTagName("script")[0];g.parentNode.insertBefore(e,g)}})(document,window.mixpanel||[]);
          
          mixpanel.init('${
            process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN
          }', {debug: ${process.env.NODE_ENV !== 'production'}}); 
      `,
        }}
      />
      {/* eslint-disable no-useless-escape */}
      <Script
        id="mixpanel-last-utms"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            if (window.mixpanel) {
              function getQueryParam(url, param) {
                  // Expects a raw URL
                  param = param.replace(/[[]/, "[").replace(/[]]/, "]");
                  var regexS = "[?&]" + param + "=([^&#]*)",
                      regex = new RegExp(regexS),
                      results = regex.exec(url);
                  if (results === null || (results && typeof (results[1]) !== 'string' && results[1].length)) {
                      return '';
                  } else {
                      return decodeURIComponent(results[1]).replace(/\W/gi, ' ');
                  }
              };
      
              function campaignParams() {
                  var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' '),
                      kw = '',
                      params = {},
                      first_params = {};
                  var index;
                  for (index = 0; index < campaign_keywords.length; ++index) {
                      kw = getQueryParam(document.URL, campaign_keywords[index]);
                      if (kw.length) {
                          params[campaign_keywords[index] + ' [last touch]'] = kw;
                      }
                  }
                  for (index = 0; index < campaign_keywords.length; ++index) {
                      kw = getQueryParam(document.URL, campaign_keywords[index]);
                      if (kw.length) {
                          first_params[campaign_keywords[index] + ' [first touch]'] = kw;
                      }
                  }
                  mixpanel.people.set(params);
                  mixpanel.people.set_once(first_params);
                  mixpanel.register(params);
              }
              campaignParams();
          }
      `,
        }}
      />
      {/* eslint-enable no-useless-escape */}
      {user && (
        <Script
          id="mixpanel-user-config"
          type="text/plain"
          data-cookieconsent="marketing"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              if (window.mixpanel) {
                try {
                    window.mixpanel.identify('${user.id}');
                    window.mixpanel.people.set({
                        '$email': '${user.email}',
                        '$first_name': '${user.profile?.first_name}',
                        '$last_name': '${user.profile?.last_name}',
                        '$full_name': '${user.profile?.name}',
                        'Contact UUID': '${user.id}',
                        'Role': '${user.profile?.role}',
                    });
                    window.mixpanel.register({
                        'Contact UUID': '${user.id}',
                    });
                } catch (error) {}
              }
      `,
          }}
        />
      )}
    </>
  )
}
