import {SyntheticEvent, useContext, useState} from 'react'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'

import Image from 'next/legacy/image'
import {useRouter} from 'next/router'

import {AuthenticationContext} from '../../lib/auth'
import useHasMounted from '../../lib/hooks/useHasMounted'
import SearchBar from '../forms/SearchBar'
import {categoryLinks} from '../index/IndexSubnav'
import ResubscribeAlert from './ResubscribeAlert'

export default function DefaultMobileNav() {
  const [expanded, setExpanded] = useState(false)
  const hasMounted = useHasMounted()
  const router = useRouter()
  const {user, signOut, openAuthModal} = useContext(AuthenticationContext)

  const onClickRedirect = (href: string) => {
    return (e: SyntheticEvent) => {
      if (e.type === 'keydown') {
        const event = e as React.KeyboardEvent
        if (event.key !== 'Enter') {
          return
        }
      }
      setExpanded(false)
      router.push(href)
    }
  }

  return (
    <div className="d-lg-none">
      <Navbar expanded={expanded} expand="lg" className="shadow">
        <Navbar.Brand className="navbar-brand d-flex align-items-center">
          <Image
            onClick={onClickRedirect('/')}
            alt="Eyes On Eyecare Glance logo"
            src="/logos/eoe-glance-logo.png"
            width="100"
            height="35"
            objectFit="contain"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="mobileNav"
          onClick={() => setExpanded(!expanded)}
        />
        <Navbar.Collapse id="mobileNav">
          <Nav className="me-auto">
            <div className="my-3 mx-1 py-3 border-top">
              <div className="mb-2">
                <SearchBar onSubmit={() => setExpanded(false)} />
              </div>
              <div className="d-flex justify-content-start">
                {user ? (
                  <span
                    tabIndex={0}
                    role="button"
                    onKeyDown={signOut}
                    onClick={signOut}
                    className="btn btn-sm btn-link m-0 me-2"
                  >
                    Sign Out
                  </span>
                ) : (
                  <>
                    <span
                      tabIndex={0}
                      role="button"
                      onKeyDown={() => openAuthModal()}
                      onClick={() => openAuthModal()}
                      className="btn btn-sm btn-bold m-0 me-2"
                    >
                      Subscribe
                    </span>
                  </>
                )}
              </div>
              <div className="d-flex flex-column mt-3 border-top pt-3">
                {hasMounted && (
                  <strong className="me-3">
                    {new Date().toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long', // 'short' or 'long'
                      day: 'numeric',
                    })}
                  </strong>
                )}
                <span className="text-strong text-dark mb-1 serif mt-3">
                  Latest Stories
                </span>
                {categoryLinks.map((link, i) => (
                  <a key={i} href={link.link}>
                    {link.text}
                  </a>
                ))}
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Clinical Articles
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/?clinical_specialty=Ocular+Surface">
                    Ocular Surface
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/?clinical_specialty=Contact+Lens">
                    Contact Lenses
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/?clinical_specialty=Glaucoma">
                    Glaucoma
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/?clinical_specialty=Retina">
                    Retina
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/?clinical_specialty=Pediatrics">
                    Pediatrics
                  </a>
                </span>
                <span>
                  {/* disabled due to algolia router compat issues */}
                  {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                  <a href="https://eyesoneyecare.com/resources/">
                    <span className="text-muted text-small">
                      SEE MORE &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">Jobs</span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Optometrist">
                    Optometrist Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Ophthalmologist">
                    Ophthalmologist Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/?role=Optician">
                    Optician &amp; Technician Jobs
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/eyecare-hiring-products/">
                    Hiring Services for Employers
                  </a>
                </span>
                <span>
                  <a href="https://jobs.eyesoneyecare.com/jobs/">
                    <span className="text-muted text-small">
                      SEE ALL JOBS &raquo;
                    </span>
                  </a>
                </span>
              </div>
              <div className="d-flex flex-column mt-3">
                <span className="text-strong text-dark mb-1 serif">
                  Virtual Events
                </span>
                <span>
                  <a href="https://events.eyesoneyecare.com/#events">
                    Upcoming Events
                  </a>
                </span>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <ResubscribeAlert />
    </div>
  )
}
