import {useContext} from 'react'

import Script from 'next/script'

import {G_GA4_ID} from '../../lib/analytics/appAnalytics'
import {AuthenticationContext} from '../../lib/auth'

export default function GoogleTagManagerInit() {
  const {user} = useContext(AuthenticationContext)

  return (
    <>
      <Script
        strategy="afterInteractive"
        type="text/plain"
        data-cookieconsent="statistics"
        src={`https://www.googletagmanager.com/gtag/js?id=${G_GA4_ID}`}
      />
      <Script
        id="gtag-init"
        type="text/plain"
        data-cookieconsent="statistics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.GAPageProperties = {};
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('set', 'linker', {
              accept_incoming: true,
              domains: ['courses.eyesoneyecare.com', 'eyesoneyecare.com', 'glance.eyesoneyecare.com', 'events.eyesoneyecare.com'],
            })
            gtag('js', new Date());
            gtag('config', '${G_GA4_ID}', {
              custom_map: {
                dimension18: 'glance-Category',
                dimension19: 'glance-Sponsor',
                dimension20: 'glance-Organizations',
              },
            })
          `,
        }}
      />
      {user && (
        <Script
          id="gtag-user-config"
          type="text/plain"
          data-cookieconsent="statistics"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              GAPageProperties.dimension2 = ${user.id}
              GAPageProperties.user_id = ${user.id}
              gtag('config', '${G_GA4_ID}', {
                send_page_view: false,
                "User ID": ${user.id}, // for custom dimension
                user_id: ${user.id},
              })
            `,
          }}
        />
      )}
    </>
  )
}
