import {useEffect, useState} from 'react'
import {useController} from 'react-hook-form'

import {HS_ROLE_OPTOMETRIST} from '../../lib/constants'
import FormError from './FormError'

export default function GraduationYearInput({
  control,
  inputName,
  role,
  required,
  errors,
}) {
  const [label, setLabel] = useState('')

  useEffect(() => {
    if (role === HS_ROLE_OPTOMETRIST) {
      setLabel('What year did you or will you graduate from optometry school?')
    } else {
      setLabel('What year did you or will you finish your residency?')
    }
  }, [role])

  const {
    field: {onChange, onBlur, name, value, ref},
  } = useController({
    name: inputName,
    control,
    defaultValue: new Date().getFullYear(),
    rules: {required: required ? 'Graduation year is required.' : false},
  })

  return (
    <>
      <label htmlFor="gradYear" className="form-label mb-0">
        {label}
        <span className="text-danger">*</span>
      </label>
      <FormError name="graduation_year" errors={errors} />
      <input
        id="gradYear"
        className="form-control"
        type="number"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        name={name}
        ref={ref}
      />
    </>
  )
}
