import {useController} from 'react-hook-form'
import Select from 'react-select'

import {
  HS_ROLE_CONSUMER,
  HS_ROLE_INDUSTRY_PRO,
  HS_ROLE_OPHTHALMIC_TECH,
  HS_ROLE_OPHTHALMOLOGIST,
  HS_ROLE_OPTICIAN,
  HS_ROLE_OPTOMETRIC_TECH,
  HS_ROLE_OPTOMETRIST,
  HS_ROLE_STAFF,
} from '../../lib/constants'

export const roles = [
  {label: 'Optometrist', value: HS_ROLE_OPTOMETRIST},
  {label: 'Ophthalmologist', value: HS_ROLE_OPHTHALMOLOGIST},
  {label: 'Optician', value: HS_ROLE_OPTICIAN},
  {label: 'Optometric Technician', value: HS_ROLE_OPTOMETRIC_TECH},
  {label: 'Ophthalmic Technician', value: HS_ROLE_OPHTHALMIC_TECH},
  {label: 'Administrative/Practice Staff', value: HS_ROLE_STAFF},
  {label: 'Industry Professional', value: HS_ROLE_INDUSTRY_PRO},
  {label: 'Consumer/Patient', value: HS_ROLE_CONSUMER},
]

/**
 * Render a single select dropdown of eyecare roles.
 *
 * @param control
 * @param inputName
 * @returns {JSX.Element}
 * @constructor
 */
const RolesSelectInput = ({control, inputName}) => {
  const {
    field: {onChange, onBlur, name, value, ref},
  } = useController({
    name: inputName,
    control,
    rules: {required: 'Role is required.'},
  })

  return (
    <Select
      id="roles"
      instanceId="roles"
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      name={name}
      inputRef={ref}
      options={roles}
    />
  )
}
export default RolesSelectInput
