import Script from 'next/script'

/**
 * These scripts are required to load the Google Publisher Tag ads such as the Header and Sticky Footer ads
 */
export default function GPTScripts() {
  return (
    <>
      <Script
        id="gpt-script"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      />
      <Script
        id="googletag-init"
        type="text/plain"
        data-cookieconsent="marketing"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          var googletag = googletag || {}; googletag.cmd = googletag.cmd || [];
          var googletagUtils = {
            hideOverflow: function(id) {
              var d = document.getElementById(id);
              d.style['overflow'] = 'hidden';
            }
          }
          `,
        }}
      />
    </>
  )
}
