import {useEffect, useState} from 'react'

import Image from 'next/legacy/image'
import Link from 'next/link'

import DefaultMobileNav from '../nav/DefaultMobileNav'
import ResubscribeAlert from '../nav/ResubscribeAlert'
import JobsDropdown from '../nav/dropdowns/JobsDropdown'
import ResourcesDropdown from '../nav/dropdowns/ResourcesDropdown'
import StoriesDropdown from '../nav/dropdowns/StoriesDropdown'
import UserDropdown from '../nav/dropdowns/UserDropdown'

export default function DefaultNav() {
  const [shadow, setShadow] = useState(false)

  useEffect(() => {
    function handleScroll() {
      if (window.pageYOffset > 0) {
        setShadow(true)
      } else {
        setShadow(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <>
      <DefaultMobileNav />
      <nav
        id="navSticky"
        className={`sticky-top bg-white d-none d-lg-block p-0 ${
          shadow ? 'shadow' : ''
        }`}
      >
        <div className="container py-4">
          <div className="d-flex justify-content-between align-items-center">
            <Link href="/" legacyBehavior>
              <a className="navbar-brand p-0 no-hover-underline d-flex justify-content-end align-items-center">
                <Image
                  alt="Eyes On Eyecare Glance logo"
                  src="/logos/eoe-glance-logo.png"
                  width="166"
                  height="75"
                  objectFit="contain"
                  className="pe-3"
                />
              </a>
            </Link>
            <div className="d-flex justify-content-end align-items-center">
              <StoriesDropdown />
              <ResourcesDropdown />
              <a
                href="https://events.eyesoneyecare.com/"
                className="text-small me-3"
              >
                Events
              </a>
              <a
                href="https://courses.eyesoneyecare.com/"
                className="text-small me-3"
              >
                Courses
              </a>
              <JobsDropdown />
              <UserDropdown />
            </div>
          </div>
        </div>
        <ResubscribeAlert />
      </nav>
    </>
  )
}
