import {z} from 'zod'

import {useContext, useState} from 'react'

import Image from 'next/image'

import {parselyTrackLeadCapture} from '../../lib/analytics/appAnalytics'
import {AuthenticationContext} from '../../lib/auth'
import SignInForm from './SignInForm'
import {SignOutComponent} from './SignOutComponent'
import SignUpForm from './SignUpForm'

const SignUpStateSchema = z.enum(['signUp', 'signIn'])

export const SubscribeComponent = () => {
  const [signUpState, setSignUpState] =
    useState<z.infer<typeof SignUpStateSchema>>('signUp')
  const {user} = useContext(AuthenticationContext)

  const track = () => {
    parselyTrackLeadCapture('Glance - Sign Up')
  }

  return (
    <div>
      <div className="d-flex justify-content-center">
        <Image
          alt="Eyes On Eyecare Glance logo"
          src="/logos/eoe-glance-logo.png"
          className="mb-3"
          width={128}
          height={37.5}
        />
      </div>
      {user && <SignOutComponent />}
      {!user && signUpState === 'signUp' && (
        <div>
          <SignUpForm onSuccess={track} />
          <div className="lead text-center">
            Already subscribed?{' '}
            <span
              onClick={() => setSignUpState('signIn')}
              className="text-primary"
              style={{cursor: 'pointer'}}
            >
              <u>Sign In</u>
            </span>
          </div>
        </div>
      )}
      {!user && signUpState === 'signIn' && (
        <div>
          <SignInForm />
          <div className="lead text-center pt-4">
            Need to{' '}
            <span
              onClick={() => setSignUpState('signUp')}
              style={{cursor: 'pointer'}}
              className="text-primary"
            >
              <u>Subscribe</u>
            </span>{' '}
            instead?
          </div>
        </div>
      )}
    </div>
  )
}
