import {z} from 'zod'

import React, {useEffect, useRef, useState} from 'react'

import {faSearch} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

const SearchBarProps = z.object({
  onSubmit: z.function().optional(),
  placeholder: z.string().optional(),
  containerClass: z.string().optional(),
  inputClass: z.string().optional(),
  feedPath: z.enum(['resources', 'stories']).optional(),
  buttonText: z.string().optional(),
  buttonClass: z.string().optional(),
  displayIcon: z.boolean().optional(),
  rendered: z.boolean().optional(),
})

export default function SearchBar({
  onSubmit,
  placeholder = 'Search by keyword',
  containerClass = '',
  inputClass = '',
  feedPath = 'stories',
  buttonText = 'Search',
  buttonClass = 'btn-primary',
  displayIcon = true,
  rendered,
}: z.infer<typeof SearchBarProps>) {
  const [route, setRoute] = useState('')
  const inputRef = useRef<HTMLInputElement>(null)

  // used to focus on input after a transition
  useEffect(() => {
    if (rendered) {
      inputRef.current && inputRef.current.focus()
    }
  }, [rendered])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRoute(e.target.value)
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (onSubmit) {
      onSubmit()
    }
    window.location.href = `/${feedPath}/?q=${route}`
  }

  return (
    <form className={`${containerClass}`} onSubmit={handleSubmit}>
      <div className="input-group">
        <input
          ref={inputRef}
          onChange={handleChange}
          value={route}
          className={`form-control form-control-sm ${inputClass}`}
          type="text"
          placeholder={placeholder}
        />
        <button
          type="submit"
          className={`btn btn-sm d-flex align-items-center ${buttonClass}`}>
          {displayIcon && (
            <FontAwesomeIcon icon={faSearch} className="text-white" />
          )}
          {buttonText && <span className="ms-1">{buttonText}</span>}
        </button>
      </div>
    </form>
  )
}
