import {useContext} from 'react'
import {useForm} from 'react-hook-form'

import {AuthenticationContext} from '../../lib/auth'
import {useUrl} from '../../lib/hooks/useUrl'
import {trpc} from '../../utils/trpc'
import FormError from './FormError'

export default function SignInForm() {
  const submitSignIn = trpc.auth.signIn.useMutation()

  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm()

  const {user} = useContext(AuthenticationContext)
  const {url} = useUrl()
  const onSubmit = (data: any) => {
    if (!data || !data.email) return
    submitSignIn.mutate({email: data.email, next: url ?? undefined})
  }
  return (
    <>
      <h3 id="subscribe" className="text-center">
        Sign in to Glance!
      </h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="d-flex flex-column">
          {/* Email */}
          <div className="mb-3">
            <label htmlFor="email" className="form-label mb-0">
              Email<span className="text-danger">*</span>
            </label>
            <FormError name="email" errors={errors} />
            <input
              id="email"
              className="form-control"
              {...register('email', {
                required: 'Email address is required.',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address.',
                },
              })}
            />
          </div>

          <button
            className="btn btn-lg btn-primary"
            type="submit"
            disabled={submitSignIn.isLoading || !!user}
          >
            {submitSignIn.isLoading ? 'Signing In...' : 'Sign In'}
          </button>
          {submitSignIn.isSuccess && (
            <div className="text-center">{submitSignIn.data.message}</div>
          )}
        </div>
      </form>
    </>
  )
}
