import Script from 'next/script'

import {
  FB_CC_PIXEL_ID,
  FB_OPHTHALMOLOGY_PIXEL_ID,
} from '../../lib/analytics/appAnalytics'

export default function FacebookPixelInit() {
  return (
    <Script
      id="fb-pixel"
      type="text/plain"
      data-cookieconsent="marketing"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${FB_OPHTHALMOLOGY_PIXEL_ID}');
        fbq('init', '${FB_CC_PIXEL_ID}');
        fbq('track', 'PageView');
      `,
      }}
    />
  )
}

export function FacebookPixel() {
  return (
    <>
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt=""
          height="1"
          width="1"
          style={{display: 'none'}}
          data-cookieconsent="marketing"
          data-cookieblock-src={`https://www.facebook.com/tr?id=${FB_OPHTHALMOLOGY_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
      <noscript>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          alt=""
          height="1"
          width="1"
          style={{display: 'none'}}
          data-cookieconsent="marketing"
          data-cookieblock-src={`https://www.facebook.com/tr?id=${FB_CC_PIXEL_ID}&ev=PageView&noscript=1`}
        />
      </noscript>
    </>
  )
}
