import useHasMounted from '../../lib/hooks/useHasMounted'
import SearchIcon from '../forms/SearchIcon'

export const categoryLinks = [
  {
    text: 'Business',
    link: '/stories/?category=Business',
  },
  {
    text: 'Legal',
    link: '/stories/?category=Legal',
  },
  {
    text: 'Pipeline',
    link: '/stories/?category=Pipeline',
  },
  {
    text: 'Research',
    link: '/stories/?category=Research',
  },
  {
    text: 'Events',
    link: '/stories/?category=Events',
  },
  {
    text: 'Products',
    link: '/stories/?category=Products',
  },
]
export default function IndexSubnav() {
  const hasMounted = useHasMounted()
  return (
    <div className="d-none d-md-block border-top border-bottom py-2 mt-4 mt-lg-0 mb-4">
      <div className="d-flex justify-content-between align-items-center">
        <div className="w-25 d-flex align-items-center">
          {hasMounted && (
            <strong className="me-3 text-uppercase">
              {new Date().toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long', // 'short' or 'long'
                day: 'numeric',
              })}
            </strong>
          )}
        </div>
        <div className="d-flex justify-content-around w-100">
          {categoryLinks.map((link, i) => (
            <a key={i} href={link.link} className="me-3 text-center">
              {link.text}
            </a>
          ))}
        </div>
        <div className="d-none d-lg-block">
          <SearchIcon />
        </div>
      </div>
    </div>
  )
}
