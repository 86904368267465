import {ANALYTICS_ENABLED} from '../../lib/analytics/appAnalytics'
import AdRollInit from './AdRollInit'
import FacebookPixelInit from './FacebookPixelInit'
import GoogleAnalyticsInit from './GoogleAnalyticsInit'
import HubspotInit from './HubspotInit'
import LinkedInInit from './LinkedInInit'
import MixpanelInit from './MixpanelInit'

export default function AppAnalyticsInits() {
  return ANALYTICS_ENABLED ? (
    <>
      <GoogleAnalyticsInit />
      <FacebookPixelInit />
      <AdRollInit />
      <MixpanelInit />
      <LinkedInInit />
      <HubspotInit />
    </>
  ) : null
}
