import {useContext} from 'react'

import {AuthenticationContext} from '../../lib/auth'
import SignUpForm from './SignUpForm'

export const SignOutComponent = () => {
  const {user, signOut} = useContext(AuthenticationContext)
  if (!user) return <SignUpForm />
  return (
    <div className="m-5">
      <div className="lead">
        Hello{user.profile ? ` ${user.profile.name}!` : '!'}
      </div>
      <div>
        You are logged in as {user.email}. Do you need to{' '}
        <span
          style={{cursor: 'pointer'}}
          onClick={signOut}
          className="text-primary pe-auto"
        >
          <u>Sign Out</u>
        </span>
      </div>
    </div>
  )
}
