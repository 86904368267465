import {useState} from 'react'
import Collapse from 'react-bootstrap/Collapse'

import {faSearch, faXmark} from '@fortawesome/pro-duotone-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import SearchBar from '../forms/SearchBar'

export default function SearchIcon() {
  const [open, setOpen] = useState(false)
  const [rendered, setRendered] = useState(false)

  return (
    <div className="d-flex align-items-center" style={{minHeight: '50px'}}>
      <Collapse
        in={open}
        onEntered={() => setRendered(true)}
        onExit={() => setRendered(false)}>
        <div className="me-2">
          <SearchBar buttonText="" feedPath="stories" rendered={rendered} />
        </div>
      </Collapse>
      <div className="d-flex justify-content-center" style={{minWidth: '30px'}}>
        <FontAwesomeIcon
          icon={open ? faXmark : faSearch}
          size={'xl'}
          // tabIndex={0}
          className="text-primary"
          style={{cursor: 'pointer'}}
          onClick={() => setOpen(!open)}
        />
      </div>
    </div>
  )
}
