import {categoryLinks} from '../../index/IndexSubnav'

export default function StoriesDropdown() {
  return (
    <span className="nav-item dropdown me-3 text-uppercase">
      <a
        className="nav-link dropdown-toggle"
        href="#"
        id="resourcesDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        Stories
      </a>

      <div className="dropdown-menu" aria-labelledby="resourcesDropdown">
        {categoryLinks.map((link, i) => (
          <a className="dropdown-item" key={i} href={link.link}>
            {link.text}
          </a>
        ))}
        <div className="dropdown-divider" />
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a className="dropdown-item" href="/stories/?category=Archives">
          Archives
        </a>
        <div className="dropdown-divider" />
        {/* disabled due to algolia router compat issues */}
        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
        <a className="dropdown-item" href="/stories/">
          See All Stories
        </a>
      </div>
    </span>
  )
}
