import {useContext} from 'react'

import Link from 'next/link'

import {faUserCircle} from '@fortawesome/pro-duotone-svg-icons'
import {
  faBookmark,
  faEnvelope,
  faHistory,
  faSignOut,
  faThumbsUp,
} from '@fortawesome/pro-light-svg-icons'
import {faSparkles} from '@fortawesome/pro-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

import {AuthenticationContext} from '../../../lib/auth'

export default function UserDropdown() {
  const {user, isAuthenticating, signOut, openAuthModal} = useContext(
    AuthenticationContext,
  )
  return (
    <div>
      {!user && !isAuthenticating && (
        <a className="btn btn-sm btn-primary" onClick={() => openAuthModal()}>
          <FontAwesomeIcon icon={faSparkles} className="me-1" />
          Subscribe
        </a>
      )}
      {user && !isAuthenticating && (
        <span className="nav-item dropdown text-uppercase">
          <a
            className="no-hover-underline"
            href="#"
            id="accountDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <FontAwesomeIcon
              size="2x"
              icon={faUserCircle}
              className="text-primary"
            />
          </a>
          <div className="dropdown-menu">
            <Link href="https://eyesoneyecare.com/account/liked" legacyBehavior>
              <a className="dropdown-item">
                <FontAwesomeIcon
                  icon={faThumbsUp}
                  style={{minWidth: '20px'}}
                  className="text-primary me-2"
                />
                Liked
              </a>
            </Link>
            <Link href="https://eyesoneyecare.com/account/saved" legacyBehavior>
              <a className="dropdown-item">
                <FontAwesomeIcon
                  style={{minWidth: '20px'}}
                  icon={faBookmark}
                  className="text-primary me-2"
                />
                Saved
              </a>
            </Link>
            <Link
              href="https://eyesoneyecare.com/account/history"
              legacyBehavior
            >
              <a className="dropdown-item">
                <FontAwesomeIcon
                  icon={faHistory}
                  style={{minWidth: '20px'}}
                  className="text-primary me-2"
                />
                Recently Read
              </a>
            </Link>
            <Link
              href="https://eyesoneyecare.com/manage-subscriptions/"
              legacyBehavior
            >
              <a className="dropdown-item">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{minWidth: '20px'}}
                  className="text-primary me-2"
                />
                Manage Subscriptions
              </a>
            </Link>
            <div className="dropdown-divider" />
            <button
              type="button"
              aria-label="Sign Out"
              className="dropdown-item text-uppercase fw-semibold"
              onClick={signOut}
            >
              <FontAwesomeIcon
                icon={faSignOut}
                style={{minWidth: '20px'}}
                className="text-primary me-2"
              />
              Sign Out
            </button>
          </div>
        </span>
      )}
    </div>
  )
}
