export const baseUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : 'https://glance.eyesoneyecare.com'

/**
 * These values map directly to the Hubspot contact property for roles.
 * https://app.hubspot.com/property-settings/4099792/properties?action=edit&property=role&search=role&type=0-1
 */
export const HS_ROLE_OPTOMETRIST = 'Optometrist'
export const HS_ROLE_OPHTHALMOLOGIST = 'Ophthalmologist'
export const HS_ROLE_OPTICIAN = 'Optician'
export const HS_ROLE_OPTOMETRIC_TECH = 'Optometric Technician'
export const HS_ROLE_OPHTHALMIC_TECH = 'Ophthalmic Technician'
export const HS_ROLE_STAFF = 'Practice Staff'
export const HS_ROLE_INDUSTRY_PRO = 'Industry Professional'
export const HS_ROLE_CONSUMER = 'Consumer/Patient'

export const CC_ADDRESS = 'San Diego, CA'
export const CC_PHONE_NUMBER = '+1 858-246-7066'

export const optometristSpecialtyOptions = [
  {value: 'Cornea and Contact Lenses', label: 'Cornea and Contact Lenses'},
  {value: 'Dry Eye', label: 'Dry Eye'},
  {value: 'Low Vision', label: 'Low Vision'},
  {value: 'Ocular Disease', label: 'Ocular Disease'},
  {value: 'Pediatrics', label: 'Pediatrics'},
  {value: 'Primary Eyecare', label: 'Primary Eyecare'},
  {
    value: 'Refractive and Ocular Surgery',
    label: 'Refractive and Ocular Surgery',
  },
  {
    value: 'Sports Vision',
    label: 'Sports Vision',
  },
  {
    value: 'Vision Therapy and Rehabilitation',
    label: 'Vision Therapy and Rehabilitation',
  },
]

export const ophthalmologistSpecialtyOptions = [
  {value: 'Anterior Segment Surgery', label: 'Anterior Segment Surgery'},
  {
    value: 'Cataracts and Refractive Surgery',
    label: 'Cataracts and Refractive Surgery',
  },
  {
    value: 'Cornea and External Disease',
    label: 'Cornea and External Disease',
  },
  {value: 'Glaucoma', label: 'Glaucoma'},
  {value: 'Neuro-Ophthalmology', label: 'Neuro-Ophthalmology'},
  {value: 'Ocular Oncology', label: 'Ocular Oncology'},
  {
    value: 'Oculoplastics and Orbit Surgery',
    label: 'Oculoplastics and Orbit Surgery',
  },
  {value: 'Ophthalmic Pathology', label: 'Ophthalmic Pathology'},
  {value: 'Pediatrics', label: 'Pediatrics'},
  {value: 'Uveitis and Immunology', label: 'Uveitis and Immunology'},
  {value: 'Vitreo-Retinal Surgery', label: 'Vitreo-Retinal Surgery'},
  {value: 'Not completing fellowship', label: 'Not completing fellowship'},
]
