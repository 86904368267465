import React, {useContext, useEffect, useState} from 'react'

import {AuthenticationContext} from '../../lib/auth'
import useLocalStorage from '../../lib/hooks/useLocalStorage'
import {trpc} from '../../utils/trpc'

const ResubscribeAlert: React.FC = () => {
  const {user} = useContext(AuthenticationContext)

  // Local storage state of the last date the user dismissed the resubscribe alert
  const [resubscribeDismissDate, setResubscribeDismissDate] = useLocalStorage(
    'resubscribeDismissDate',
    null,
  )
  const [hasBeenDismissed, setHasBeenDismissed] = useState(true)
  const [showSuccess, setShowSuccess] = useState(false)
  const [isOptedOut, setIsOptedOut] = useState(false)

  // TRPC mutation to send a resubscribe email to the user
  const useResubscribe = trpc.auth.resubscribe.useMutation({
    onSuccess: () => {
      setHasBeenDismissed(true)
      setShowSuccess(true)
      setResubscribeDismissDate(new Date().toISOString())
    },
  })
  // TRPC query to check if the user is opted out of emails
  trpc.auth.isOptedOutOfEmails.useQuery(undefined, {
    refetchOnWindowFocus: false,
    enabled: !hasBeenDismissed && !!user,
    onSuccess: (data) => {
      setIsOptedOut(data)
    },
  })

  /**
   * Fire on mount and when localStorage changes for the resubscribeDismissDate variable.
   * Show the resubscribe alert if the user has not dismissed it in the last 30 days.
   */
  useEffect(() => {
    if (!resubscribeDismissDate) {
      return setHasBeenDismissed(false)
    }
    const dismissDate = new Date(resubscribeDismissDate)
    const thirtyDaysAgo = new Date( // Yeah, JS dates are dumb. This is a monstrocity.
      new Date().setDate(new Date().getDate() - 30),
    )
    setHasBeenDismissed(dismissDate > thirtyDaysAgo)
  }, [resubscribeDismissDate])

  return (
    <div>
      {isOptedOut && !hasBeenDismissed && (
        <div className="bg-danger text-center text-white p-3 mb-2">
          <div className="container">
            <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-2">
              <span>
                You&apos;re currently unsubscribed from Eyes On Eyecare email.
                To continue receiving content, resubscribe now!
              </span>
              <span>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-light"
                  onClick={() => useResubscribe.mutate()}
                >
                  Resubscribe
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-link text-white"
                  onClick={() =>
                    setResubscribeDismissDate(new Date().toISOString())
                  }
                >
                  Dismiss
                </button>
              </span>
            </div>
          </div>
        </div>
      )}
      {showSuccess && (
        <div className="bg-primary text-center text-white p-3 mb-2">
          <div className="container">
            <div
              className="d-flex justify-content-between align-items-center"
              data-bs-theme="dark"
            >
              <span>
                Check your email and follow the link to resubscribe to Eyes On
                Eyecare!
              </span>
              <button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => setShowSuccess(false)}
              ></button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default ResubscribeAlert
