import DefaultFooter from './DefaultFooter'
import DefaultHead from './DefaultHead'
import DefaultNav from './DefaultNav'

interface DefaultLayoutProps {
  hideFooter: boolean
  hideHead: boolean
  hideNav: boolean
  pageType: string
  children: JSX.Element
}

export default function DefaultLayout({
  hideFooter,
  hideHead,
  hideNav,
  pageType,
  children,
}: DefaultLayoutProps) {
  return (
    <>
      {!hideHead && <DefaultHead />}
      {!hideNav && <DefaultNav />}
      <main>{children}</main>
      {!hideFooter && <DefaultFooter pageType={pageType} />}
    </>
  )
}
// The purpose of this function is to allow us to return a complex nested tree of layouts
// https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/

// @ts-expect-error we are going to use next's layout features
export const getLayout = (page) => <DefaultLayout>{page}</DefaultLayout>
